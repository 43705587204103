<template>
  <div>
    <p style="text-align: right" class="is-size-4 has-text-grey-dark">
      {{ $t('Property Loader') }}
    </p>
    <keep-alive>
      <result-tables
        v-if="successList.length > 0 || errorList.length > 0"
        :success-data="successDisplayList"
        :error-data="errorList"
        :success-columns="successColumns"
        :error-columns="errorColumns"
      />
    </keep-alive>
    <b-button type="is-info" @click="showHelp = !showHelp">
      {{ showHelp ? $t('Hide guide') : $t('Show guide') }}
    </b-button>
    <br />
    <b-message
      :active.sync="showHelp"
      :title="$t('Using the Property Loader')"
      style="padding-top: 10px"
      class="is-info"
    >
      <span v-html="$t('Property Loader Help')"></span>
    </b-message>

    <file-loader
      v-if="!error"
      :url="loaderURL"
      :customer="customer"
      :emit-type="emitType"
      :environment="environment"
      @upload-started="uploadStarted()"
    />
    <span v-if="error">{{ error }}</span>
    <br />
    <a
      :href="csvTemplate"
      download="property-template.csv"
      class="has-text-link"
      >{{ $t('Download CSV Template') }}</a
    >
    <div v-if="retrieved">
      <p>{{ $t('Successful') }}: {{ successList.length }}</p>
      <p>{{ $t('Errors') }}: {{ errorList.length }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import FileLoader from './FileLoader';
import { eventBus } from '@/events/events';
import {
  getSuccesses,
  getErrors,
} from '@/components/ofsc/helpers/loaderHelper';
import ResultTable from './ResultTable';

export default defineComponent({
  name: 'PropertyLoader',
  components: {
    FileLoader,
    ResultTables: ResultTable,
  },
  props: {
    environment: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emitType: 'propertyLoadResults',
      showHelp: true,
      retrieved: false,
      error: null,
      errorList: [],
      result: null,
      successList: [],
      successColumns: [
        {
          field: 'label',
          label: 'Label',
          width: '100',
          numeric: false,
        },
        {
          field: 'name',
          label: 'Name',
          width: '100',
          numeric: false,
        },
      ],
      errorColumns: [
        {
          field: 'statusCode',
          label: 'Status Code',
          width: '40',
          numeric: true,
        },
        {
          field: 'request',
          label: 'Request',
          width: '100',
          numeric: false,
        },
        {
          field: 'error',
          label: 'Error',
          width: '100',
          numeric: false,
        },
        {
          field: 'body',
          label: 'Content',
          width: '100',
          numeric: false,
        },
      ],
    };
  },
  computed: {
    loaderURL() {
      return this.getURL();
    },
    csvTemplate() {
      return require('@/assets/csv/PropertiesTemplate.csv').default;
    },
    successDisplayList() {
      let retVal = [];
      this.successList.forEach((row) => {
        retVal.push({
          label: row.result.label,
          name: row.result.name,
        });
      });
      return retVal;
    },
  },
  created() {
    eventBus.on(this.emitType, (data) => {
      this.retrieved = true;
      this.result = data;
      this.successList = getSuccesses(data);
      this.errorList = getErrors(data);
      this.showHelp = false;
    });
  },
  beforeDestroy() {
    eventBus.off(this.emitType);
  },
  mounted() {
    while (this.successList.length > 0) {
      this.successList.pop();
    }
    while (this.errorList > 0) {
      this.errorList.pop();
    }
  },
  methods: {
    uploadStarted() {
      this.emptyArray(this.successList);
      this.emptyArray(this.errorList);
    },
    getURL() {
      const baseURL = this.$configData.dataLoaders.baseURL;
      const path = this.$configData.dataLoaders.propertiesLoader.path;
      if (!baseURL || !path) {
        this.error = 'No configuration found for loader';
        return null;
      } else {
        return baseURL + path;
      }
    },
  },
});
</script>
